var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "emailsend-modal",
        size: "fs",
        title: _vm.$t("sende-mail"),
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-enforce-focus": "",
      },
      on: {
        hide: function ($event) {
          return _vm.close()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _vm.enableSending
                ? _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("sende-mail2335"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.send()
                      },
                    },
                  })
                : _vm._e(),
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-primary",
                  icon: "undo",
                  title: _vm.$t("closewithoutsen"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("vue-form-generator", {
        ref: "detailForm",
        attrs: {
          schema: _vm.schema,
          model: _vm.model,
          options: _vm.formOptions,
        },
      }),
      _c("html-text-editor", {
        attrs: { readonly: !_vm.enableSending },
        model: {
          value: _vm.model.Body,
          callback: function ($$v) {
            _vm.$set(_vm.model, "Body", $$v)
          },
          expression: "model.Body",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }