<template>
	<b-modal
		modal-class="emailsend-modal"
		size="fs"
		v-model="show"
		:title="$t('sende-mail')"
		@hide="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
	>
		<vue-form-generator :schema="schema" :model="model" :options="formOptions" ref="detailForm"></vue-form-generator>
		<html-text-editor v-model="model.Body" :readonly="!enableSending"></html-text-editor>

		<template v-slot:modal-footer>
			<megau-button
				v-if="enableSending"
				classprop="btn btn-success"
				icon="check"
				:title="$t('sende-mail2335')"
				@handleClick="send()"
			></megau-button>
			<megau-button classprop="btn btn-primary" icon="undo" :title="$t('closewithoutsen')" @handleClick="close()"></megau-button>
		</template>
	</b-modal>
</template>
<script>
import FieldCheckboxes from '../../components/detail/checkboxes.field';
import vue from 'vue';
import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);
vue.component('field-checkboxes-group', FieldCheckboxes);

import service from './email-send.service';
import model from './email-send.model';
import api from './email-sender.api';

import htmlTextEditor from '../../components/common/html-text-editor';

export default {
	components: {
		htmlTextEditor,
	},
	props: {
		templateId: { type: Number },
		entityId: { type: Number },
		typeId: { type: Number },
		sendLogEmailId: { type: Number },
		isSentEmail: { type: Boolean, default: false },
		isVisible: { type: Boolean, default: false },
		enableSending: { type: Boolean, default: true },
	},
	data() {
		return {
			controllerName: 'EmailSender',
			show: false,
			model: model.modal,
			schema: model.fields,
			formOptions: {
				validateAfterLoad: false,
				validateAfterChanged: true,
			},
		};
	},
	computed: {
		language: function () {
			return this.$i18n.locale;
		},
	},
	watch: {
		enableSending: (isEnabled) => {
			this.formState(!isEnabled);
		},
	},
	created() {
		this.formState(!this.enableSending);
	},
	methods: {
		/**
		 * change fields props
		 * @param isDisabled fields are disabled or not
		 */
		formState(isDisabled) {
			for (let i = 0, lg = this.schema.groups.length; i < lg; i++) {
				for (let j = 0, lf = this.schema.groups[i].fields.length; j < lf; j++) {
					const field = this.schema.groups[i].fields[j];
					field.disabled = isDisabled;
				}
			}
		},
		close() {
			this.$emit('onClose', false);
		},
		send() {
			this.model.EntityId = this.entityId;
			this.model.TemplateId = this.templateId;
			this.model.TypeId = this.typeId;

			api.sendEmail(this.model).then((resp) => {
				this.$emit('onClose', true);
			});
		},
		getEmail() {
			if (this.isSentEmail) {
				api.getEmailById(this.sendLogEmailId).then((resp) => {
					this.model = resp.data;
				});
			} else {
				api.getEmail(this.templateId, this.entityId).then((resp) => {
					this.model = resp.data;
				});
			}
		},
	},
	watch: {
		isVisible: function () {
			this.show = this.isVisible;
			if (this.isVisible) {
				this.getEmail();
			}
		},
		language: function () {
			service.formTranslations(this);
		},
	},
};
</script>
<style lang="scss">
.emailsend-modal {
	.modal-fs.modal-dialog {
		max-width: 98%;
	}
	.vue-form-generator .form-group > label {
		width: 8%;
	}
}

.inline-input-group-wrapper {
	.first-input {
		width: 58%;

		> label {
			width: 13.8% !important;
		}

		.field-wrap {
			width: 86.5%;
		}
	}
	.second-input {
		padding-left: 10px;
		width: 20%;

		> label {
			display: none;
		}
		.field-wrap {
			width: 100%;
			.custom-checkbox {
				flex-basis: 100%;
				> label {
					width: 100%;
				}
			}
		}
	}
}
</style>
<style lang="scss" scoped>
.actions {
	justify-content: flex-end;
	padding-top: 10px;
	> .megauButton:not(:last-child) {
		margin-right: 10px;
	}
}
</style>