import http from '../../services/helpers/http';

const api = {
	getEmail(templateId, entityId) {
		return http.get('EmailSender/GetEmail', { templateId: templateId, entityId: entityId });
	},

	getEmailById(pesLogId) {
		return http.get('EmailSender/GetEmailById', { pslogId: pesLogId });
	},

	sendEmail(message) {
		return http.post('EmailSender/SendEmail', message);
	},
};

export default api;
