var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("ckeditor", {
        attrs: {
          editor: _vm.editor,
          value: _vm.value,
          config: _vm.editorConfig,
          readonly: _vm.readonly,
          disabled: _vm.readonly,
        },
        on: { ready: _vm.onReady, input: _vm.onInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }