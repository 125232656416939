<template>
	<div class="editor">
		<ckeditor
			:editor="editor"
			:value="value"
			:config="editorConfig"
			:readonly="readonly"
			:disabled="readonly"
			@ready="onReady"
			@input="onInput"
		></ckeditor>
	</div>
</template>

<script>
//import { abstractField } from "vue-form-generator";
import CKEditor from '@ckeditor/ckeditor5-vue2';

//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';

import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
//import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
//import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import List from '@ckeditor/ckeditor5-list/src/list';
// import Table from '@ckeditor/ckeditor5-table/src/table';
// import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Font from '@ckeditor/ckeditor5-font/src/font';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import AllowAttributes from '../../plugins/ckeditor-allow-atrtributes.js';

export default {
	//mixins: [abstractField],
	name: 'htmltexteditor',
	components: {
		ckeditor: CKEditor.component,
	},
	props: {
		value: String,
		readonly: { type: Boolean, default: false },
	},
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
					Essentials,
					Bold,
					Italic,
					Underline,
					Link,
					Paragraph,
					Heading,
					Image,
					ImageCaption,
					ImageStyle,
					ImageToolbar,
					ImageUpload,
					ImageResize,
					Base64UploadAdapter,
					List,
					// Table,
					// TableToolbar,
					Font,
					RemoveFormat,
					Alignment,
					Indent,
					IndentBlock,
					AllowAttributes,
				],
				toolbar: [
					'undo',
					'redo',
					'|',
					'heading',
					'|',
					'bold',
					'italic',
					'underline',
					'link',
					'|',
					'alignment',
					'outdent',
					'indent',
					'|',
					'fontSize',
					'fontFamily',
					'fontColor',
					'fontBackgroundColor',
					'|',
					//'insertTable',
					'bulletedList',
					'numberedList',
					'|',
					'imageUpload',
					// 'imageStyle:full',
					// 'imageStyle:side',
					'|',
					'removeFormat',
				],
				fontSize: {
					options: [9, 11, 'default', 17, 19, 21, 24, 28, 35, 40],
				},

				// fontFamily: {
				// options: [
				//     'default',
				//     'Arial, sans-serif',
				//     'Courier New, Courier, monospace'
				// ]
				//},
				// heading: {
				//     options: [
				//         { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
				//         { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
				//         { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
				//     ]
				// }
				image: {
					toolbar: [
						'imageTextAlternative',
						'|',
						'imageStyle:alignLeft',
						'imageStyle:full',
						'imageStyle:side',
						'imageStyle:alignCenter',
						'imageStyle:alignRight',
					],

					styles: ['full', 'alignLeft', 'side', 'alignCenter', 'alignRight'],
				},

				link: {
					// Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
					addTargetToExternalLinks: true,
				},
			},
		};
	},
	methods: {
		onReady(editor) {
			//CKEditorInspector.attach(editor);
			////Insert the toolbar before the editable area.
			//editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
		},
		onInput(content) {
			this.$emit('input', content);
		},
	},
};
</script>

<style lang="css">
/*
	We need to add this CSS custom property to the body instead of :root,
	because of CSS specificity.
*/
body {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}

/*
	Override Bootstrap's CSS.
	Note: this won't be necessary once the following issue is fixed and released:
	https://github.com/ckeditor/ckeditor5-theme-lark/issues/189
*/
.ck.ck-button {
	-webkit-appearance: none;
}
</style>

